import { styled } from '@mui/system';

export const ComparePageHead = styled('div')`
    text-align: center;

    h2 {
        font-size: 32px;
    }

    p {
        font-size: 17px;
        margin: 0;
    }
`

export const AddressWrapper = styled('div')`
    display: flex;
    align-items: center;

    i {
        display: inline-block;
        width: 24px;
        height: 24px;
        mask: url('/images/Home.svg') 0 0 no-repeat;
        mask-size: cover;
        background-color: ${({ theme }) => theme.palette.primary.main};
        margin-right: 12px;
    }
`

export const AddressDetailRow = styled('div')`
    display: flex;
    align-items: center;
    font-size: 14px;

    span {
        flex: 1;
    }
`

export const MapWarpper = styled('div')`
    height: 200px;
    background-color: #f3f3f3;
    border-radius: 12px;
    margin-top: 12px;
`

export const MapMarker = styled('div')`
    display: inline-block;
    width: 22px;
    height: 30px;
    overflow: hidden;
    background: url('/images/pin.png') 0 0 no-repeat;
    background-size: cover;
    font-size: 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
`