import { PureComponent, useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';
// import qs from 'qs';
import { Box, Button, Grid } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import NumberFormat from 'react-number-format';
import { ChartTooltips } from './styles/common.styled';
import { MyAddressContext } from '../context-manager';
import { MyAddressContextProps } from '../types';

const tooltipStyles = {
    cursor: {
        fill: 'transparent'
    },
}

export const ShowUsage = ({
    source,
    data
}: {
    source: string
    data: any
}) => {
    const { handleSetUsage } = useContext(MyAddressContext) as MyAddressContextProps;
    // const navigate = useNavigate();
    // const location = useLocation();

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let chartData = [];
    let totalUsage = 0;
    let usage: number[] = [];

    if (source === 'tdu') {
        data.forEach((item: {
            'actual_kwh': string
        }) => {
            usage.push(parseInt(item['actual_kwh']));
        });
    }

    if (source === 'smt') {
        for (let i in data) {
            usage.push(parseInt(data[i]));
        }
    }

    for (let i in usage) {
        totalUsage += usage[i];
        chartData.push({ name: months[parseInt(i)], value: usage[i] })
    }

    const handleContinue = () => {
        handleSetUsage({
            usage,
            source
        });
        // const { address, esiid, zipcode, city, state, utcode } = qs.parse(location.search, { ignoreQueryPrefix: true });
        // navigate(`/plans?address=${encodeURIComponent(`${address}`)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&actualusage=${usage.join('-')}&usagefrom=${source}&utcode=${utcode}`);
    }

    return (
        <div>
            <Box textAlign="center">
                <img src="/images/icon-check.png" alt="" width="64" />
                <h2>Your usage insight by month</h2>
            </Box>
            <Grid container sx={{mb:3}}>
                <Grid item xs={6} textAlign="center"><div style={{fontSize:14}}>Annual Usage:</div><b><NumberFormat value={totalUsage} displayType={'text'} thousandSeparator={true} suffix=" kWh" /></b></Grid>
                <Grid item xs={6} textAlign="center"><div style={{fontSize:14}}>Avg. Monthly Usage:</div><b><NumberFormat value={Math.round(totalUsage / 12 * 100) / 100} displayType={'text'} thousandSeparator={true} suffix=" kWh" /></b></Grid>
            </Grid>
            <div>
                <ResponsiveContainer width="100%" height={260}>
                    <BarChart
                        data={chartData}
                        margin={{
                            top: 12, right: 24, left: -12, bottom: 0,
                        }}
                    >
                        <XAxis dataKey="name" tick={{fontSize:11}} stroke="#bbb" interval={1} />
                        <YAxis tick={{fontSize:11, fill: '#bbb'}} stroke="#fff" />
                        <CartesianGrid vertical={false} strokeDasharray="2" />
                        <Tooltip
                            { ... tooltipStyles }
                            wrapperStyle={{ zIndex: 1 }}
                            content={ <CustomTooltip /> }
                        />
                        <Bar dataKey="value"
                            isAnimationActive={true}
                            maxBarSize={10}
                            shape={BarWithTopBorder()}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <Box textAlign="center" sx={{mt:4}}>
                <Button size="large" variant="contained" onClick={handleContinue}>Continue</Button>
            </Box>
        </div>
    )
}

class CustomTooltip extends PureComponent {
    render() {
        const { active, payload }: Readonly<any> = this.props;
        
        if (!active || !payload || !payload[0]) return null;
        const { name, value } = payload[0].payload;
        
        return (
            <ChartTooltips>
                <h6>{name}</h6>
                <p><NumberFormat value={value} displayType={'text'} thousandSeparator={true} suffix=" kWh" /></p>
            </ChartTooltips>
        )
    }
}

const BarWithTopBorder = () => {
	return (props: any) => {
		const { x, y, width, height } = props;

		return (
			<g>
				<rect x={x} y={y} width={width} height={height} stroke="none" className="bar" rx="2" ry="2" fill="#ddd" />
			</g>
		)
	}
}