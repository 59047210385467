import { StyledFooter, FooterNav } from './styles/common.styled';
import moment from 'moment';

export const Footer = () => {
    return (
        <StyledFooter>
            <FooterNav>
                <a href="https://www.constellationenergy.com/privacy-policy.html">Privacy Policy</a> | <a href="https://www.constellationenergy.com/terms-of-use.html">Terms and Conditions</a> | <a href="https://www.constellation.com/energy-101/blog/blog-policy.html">Blog Policy</a> | 
            </FooterNav>
            <p>© {moment().format('YYYY')} Constellation Energy Resources, LLC.  The offerings described herein are those of either Constellation NewEnergy, Inc., Constellation NewEnergy-Gas Division, LLC, Constellation Connect, LLC or Constellation Home Products &amp; Services, LLC (d/b/a Constellation Home in Maryland and Pennsylvania and d/b/a Constellation in Georgia and Texas), each affiliates of each other. The prices of Constellation are not regulated by any state Public Utility Commission. You do not have to buy Constellation electricity, natural gas or any other products to receive the same quality regulated service from your local utility. Brand names and product names are trademarks or service marks of their respective holders. All rights reserved. Errors and omissions excepted.</p>
            <p>Electricity or Gas Supplier License/Order #s: CA 1359, CTA0032; CT 06-07-11, 01-06; DE 00-162; DC GA06-2, EA01-5; GA GM-46; IL 16-0205, 17-0330; IA G-0010; ME 2000-989; MD IR-655, IR-311, IR-500, IR-228, 107-2276; MA GS-030, CS-015; MI U-14867, U-13660; NE NG-0043; NH DM 17-024; NJ GSL-0101, ESL-0016; OH 09-153G, 00-003E; PA A-125095, A-110036; OR ES4, RI 2379(Z1), D-96-6(E); TX 10014, B07305101; VA G-26, G-51, E-11A</p>
            <p>TX Licenses HVAC TACLA 00107498R, Electrical TECL 343159, Service Plan TSCP #684, Plumbing #43217, TREC #168</p>
        </StyledFooter>
    )
}