import { styled } from '@mui/system';

export const StyledHeader = styled('header')`
    background-color: #fff;
    padding: 0 4vw;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
`

export const Logo = styled('a')`
    text-decoration: none;
    color: #666;
    display: flex;
    align-items: center;
    
    img {
        width: 180px;
    }

    span {
        font-size: 12px;
        margin-left: 8px;
    }

    @media (max-width: 991px) {
        img {
            width: 120px;
        }
    }
`

export const PageWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100%;

    h2 {
        font-size: 3rem;
        line-height: 1.2;
    }

    @media (max-width: 991px) {
        h2 {
            font-size: 2rem;
            text-align: center;
        }
    }
`

export const PageMain = styled('div')`
    flex: 1;
`

export const ChartWrapper = styled('div')`
    height: 400px;
    width: 100%;
    margin: 24px 0;

    @media (max-width: 991px) {
        height: 320px;
    }

    @media (max-width: 768px) {
        height: 260px;
    }
`

export const StyledFooter = styled('div')`
    background-color: #7e8083;
    color: #FFF;
    font-size: 13px;
    padding: 18px 4vw 0;

    a {
        color: #fff;
        font-size: 13px;
    }

    a:hover {
        text-decoration: underline;
    }

    p {
        font-size: 11px;
        color: #fff;
        margin-bottom: 1.5em;
    }

    @media (max-width: 768px) {
        
    }
`

export const FooterNav = styled('div')`
    border-bottom: 1px solid #fff;
    padding-bottom: 12px;
    margin-bottom: 12px;
`

export const FooterSocialLinks = styled('div')`
    display: flex;
    align-items: center;

    a {
        padding: 0.75em;
        border: 2px solid #2372B9;
        border-radius: 1000px;
        color: #fff;
        transition: background-color 0.25s ease-in-out 0s;
        display: inline-flex;
        width: 52px;
        height: 52px;
        align-items: center;
        justify-content: center;
        margin-left: .75rem;
        box-sizing: border-box;
    }

    a:first-child {
        margin-left: 0;
    }

    a:hover, a:focus {
        background-color: #2372B9;
    }

    svg {
        height: 1.5em;
        width: 1.5em;
        margin: auto;
    }

    @media (max-width: 768px) {
        margin-bottom: 32px;
    }
`

export const ChartTooltips = styled('div')`
    background: #fff;
    border: 1px solid #2372B980;
    border-radius: 8px;
    padding: 12px 16px;
    text-align: left;

    h6 {
        font-size: 12px;
        font-weight: 700;
        color: #3a3a3a;
        margin: 0 0 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #f2f2f2;
    }

    p {
        margin: 0;
    }
`

export const ViewMoreBtnRow = styled('div')`
    display: flex;
    padding-left: 2%;

    & > div {
        flex: 1;
        text-align: center;
    }

    @media (max-width: 768px) {
        padding-left: 4%;

        button {
            font-size: 11px;
            padding: 2px 6px;
        }
    }
`

export const HeaderMenu = styled('div')`
    display: flex;
    align-items: center;

    @media (max-width: 991px) {
        display: ${({ active }: { active: string }) => (active && active === 'true') ? 'flex' : 'none'};
        position: fixed;
        top: 52px;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999;
        flex-direction: column;
        align-items: flex-start;
        padding: 1.375rem 0px 0.625rem 3.5rem;
        
        button {
            margin-bottom: 1.375rem;
            font-size: 1.25rem;
            padding: 0.75rem 2.125rem;
        }
    }
`

export const HeaderMenuItem = styled('a')`
    color: #2372b9;
    font-weight: 500;
    text-decoration: none;
    font-size: 12px;

    @media (max-width: 991px) {
        font-size: 1.25rem;

        &:last-of-type {
            margin-bottom: 2.75rem;
        }
    }
`

export const StyledHeaderInner = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4em;

    button {
        box-shadow: none;
        margin-right: 1rem;

        :last-child {
            margin-right: 0;
        }
    }

    @media (max-width: 991px) {
        height: 52px;
    }
`

export const EntrySection = styled('div')`
    background: url('/images/2200x300-lights.png') center top no-repeat;
    /* margin-bottom: 64px; */
    padding: 44px 0;
`

export const MenuToggle = styled('button')`
    cursor: pointer;
    border: 0 none;
    mask-image: ${({ active }: { active: string }) => (active && active === 'true') ? 'url("/images/X.svg")' : 'url("/images/Menu.svg")'};
    mask-position: 0 0;
    mask-repeat: no-repeat;
    background-color: #888;
    width: 24px;
    height: 24px;
    mask-size: cover;
    display: none;
    margin-right: 0 !important;

    @media (max-width: 991px) {
        display: inline-block;
    }
`

export const ModalBodyStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        md: 650,
        xs: '85%'
    },
    bgcolor: '#fff',
    boxShadow: 24,
    borderRadius: 4,
    p: 2,
};

export const ChartFooter = styled('div')`
    margin-top: 24px;
    color: #999;
    font-size: 13px;
    text-align: center;
`

export const ModalCloseBtn = styled('button')`
    display: inline-block;
    width: 32px;
    height: 32px;
    mask: url('/images/X.svg') 0 0 no-repeat;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border: 0 none;
    cursor: pointer;
`

export const SavingRow = styled('div')`
    margin: 4px 0;

    span {
        color: #2372B9;
        font-size: 32px;
        font-weight: 700;
        margin-left: 12px;
    }

    @media (max-width: 768px) {
        text-align: left;

        span {
            font-size: 24px;
            margin-left: 0;
        }
    }
`