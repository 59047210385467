import React from 'react';
import ReactLoading from "react-loading";
import type { LoadingRowProps } from '../types';

export const LoadingRow: React.FC<LoadingRowProps> = ({ size }) => {
    if (size === 'sm') {
        return (
            <div style={{display:'flex',justifyContent:'center'}}>
                <ReactLoading type="bubbles" color="#bbb" width={18} height={18} />
            </div>
        )
    }
    return (
        <div style={{display:'flex',justifyContent:'center'}}>
            <ReactLoading type="bubbles" color="#bbb" width={36} height={36} />
        </div>
    )
}