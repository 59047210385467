import SavingSection from "./components/SavingSection";

const Homepage = () => {
    return (
        <>
            <SavingSection />
        </>
    )
}

export default Homepage