import {
    Modal, Box, Table, TableBody, TableCell, Link,
    TableContainer, TableHead, TableRow, Paper, Grid
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { PlanTypes } from "../types";
import moment from "moment";
import { ModalBodyStyle, ModalCloseBtn } from "./styles/common.styled";

export const PlanModal = ({ data, show, handleCloseModal }: {
    data: PlanTypes[]|undefined,
    show: boolean,
    handleCloseModal: Function
}) => {
    const location = useLocation();

    let showPrice = false;
    if (location && location.search) {
        const { search } = location;
        if (search.split('user=')[1] === 'eiq') {
            showPrice = true;
        }
    }

    return (
        <Modal
            open={show}
            onClose={() => handleCloseModal()}
        >
            <Box sx={ModalBodyStyle}>
                <Grid container justifyContent="flex-end" sx={{mt:1,mb:2}}>
                    <ModalCloseBtn onClick={() => handleCloseModal()} />
                </Grid>
                {
                    data &&
                    <TableContainer component={Paper}>
                        <Table aria-label="plan list">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Provider</TableCell>
                                    <TableCell>Plan Name</TableCell>
                                    <TableCell align="right">Plan Length</TableCell>
                                    {
                                        showPrice &&
                                        <TableCell align="right">Price</TableCell>
                                    }
                                    <TableCell align="right">EFL</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data.map((row: PlanTypes) => (
                                        <TableRow
                                            key={row.plan_id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <img src={`https://www.powerlego.com/ApiGateway/v2/logo/provider/${row.provider_id}`} width="100%" style={{maxWidth:125}} alt="" />
                                            </TableCell>
                                            <TableCell>
                                            {row.plan_name}
                                            <p style={{margin:'8px 0 0',color:'#999',fontSize:11}}>Last updated on {moment.unix(parseInt(row.update_detail_time)).format('MM/DD/YYYY hh:mm:ss a')}</p>
                                            </TableCell>
                                            <TableCell align="right">
                                            {row.contract_term} Months
                                            </TableCell>
                                            {
                                                showPrice &&
                                                <TableCell align="right"><b>{row.total_rate}¢</b></TableCell>
                                            }
                                            <TableCell align="right">
                                                <Link href={row.efl_url} target="_blank" rel="noreferrer">
                                                    <img src="/images/FileMinus.png" width="24" alt="" />
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Box>
        </Modal>
    )
}