import { styled } from '@mui/system';

export const PopLoadingWrapper = styled('div')`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`

export const PopLoading = styled('div')`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, .7);
    border-radius: 12px;
    padding: 32px 32px;
    max-width: 400px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .2);
`

export const PopLoadingContent = styled('div')`
    color: #fff;
    cursor: default;
    margin-top: 12px;
`

export const PopLoadingText = styled('div')`
    position: relative;
    top: 0;
    opacity: 1;
    transition: all 1s;

    .fade {
        top: -10px;
        opacity: 0;
    }
`