import { API_KEY, POWERLEGO_API_URL } from "../config";

export const getConsentId = async (
    esiid: string, email: string, phone: string, zipcode: string, customer_name: string, utility_code: string
) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${API_KEY}`);

    const formdata = new FormData();
    formdata.append("esiid", esiid);
    formdata.append("email", email);
    formdata.append("phone", phone);
    formdata.append("zipcode", zipcode);
    formdata.append("customer_name", customer_name);
    formdata.append("utility_code", utility_code);

    const url = `${POWERLEGO_API_URL}/v2/tdu_usage_retriever/submit`;

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    })
    return response.json()
}

export const getUsageViaTdu = async (consent_id: string, {...options}) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${API_KEY}`);

    const formdata = new FormData();
    formdata.append("consent_id", consent_id);

    const url = `${POWERLEGO_API_URL}/v2/tdu_usage_retriever/get`;

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
        ...options
    })
    return response.json()
}