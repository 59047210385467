import { Alert, Snackbar } from '@mui/material';
import { useState, ComponentType } from 'react';
import Slide, { SlideProps } from '@mui/material/Slide';

type SeverityTypes = 'error' | 'success' | 'info' | 'warning';

type ExtraInfoType = {
    extraInfo: string;
}

type TransitionProps = Omit<SlideProps, 'direction'>;

const TransitionLeft = (props: TransitionProps) => {
    return <Slide {...props} direction="left" />
}

export function withSnackbar<P> (WrappedComponent: ComponentType<P & ExtraInfoType>) {
    return (props: any) => {
        const [ show, setShow ] = useState(false);
        const [ message, setMessage ] = useState('');
        const [ type, setType ] = useState('error');

        const showMessage = (message: string, type: SeverityTypes) => {
            setMessage(message);
            setType(type);
            setShow(true);
        }

        const handleClose = (evt: any, reason: string) => {
            if (reason === 'clickaway') return;
            setShow(false);
        }

        return (
            <>
                <WrappedComponent {...props} snackbarShowMessage={showMessage} />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    autoHideDuration={3000}
                    open={show}
                    onClose={handleClose}
                    TransitionComponent={TransitionLeft}
                >
                    <Alert variant="filled" onClose={() => setShow(false)} severity={type as SeverityTypes}>{message}</Alert>
                </Snackbar>
            </>
        )
    }
}