import { styled } from '@mui/system';

export const TduContactLaterSection = styled('div')`
    position: relative;
    margin-top: 88px;

    ::before{
        display: inline-block;
        content: ' ';
        width: 300px;
        height: 1px;
        background-color: #ddd;
        position: relative;
        top: -44px;
    }
`

export const StyledFormError = styled('div')`
    color: #D33A2C;
    font-size: 13px;
`
