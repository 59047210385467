import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import SmtGetInfo from './smtProcess/GetInfo';
import SmtShowAgreement from './smtProcess/ShowAgreement';
import MyAccountInfo from './myaccountProcess/GetInfo';
import TduGetInfo from './tduProcess/GetInfo';
import { ShowUsage } from './GetUsageResult';
import type { GetUsageComponentProps } from '../types';
import { fetchUtility } from '../fetch/fetch';
import { LoadingRow } from './LoadingRow';

enum PageView {
    SOURCE,
    SMTINFO,
    SMTAGREEMENT,
    TDUINFO,
    MYACCOUNTINFO,
    USAGE
}

export const GetUsageComponent: React.FC<GetUsageComponentProps> = ({
    handleSetConsentId,
    defaultView,
    inModal,
    zipcode,
    forcastUsage
}) => {
    const navigate = useNavigate();
    const [ view, setView ] = useState(PageView.SOURCE);
    const [ consentId, setConsentId ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ recommend, setRecommend ] = useState('');
    const [ usage, setUsage ] = useState([]);
    const sourceFrom = useRef('');
    
    useEffect(() => {
        if (defaultView) {
            if (defaultView === 'smt') {
                setView(PageView.SMTINFO);
            }
            if (defaultView === 'tdu') {
                setView(PageView.TDUINFO);
            }
            if (defaultView === 'myaccount') {
                setView(PageView.MYACCOUNTINFO);
            }
        }
    }, [defaultView])

    useEffect(() => {
        if (zipcode) {
            fetchUtility(zipcode).then(res => {
                if (res) {
                    const isOncor = res[0].utility_name.toLowerCase().indexOf('oncor') >= 0;
                    setRecommend(isOncor ? 'smt' : 'tdu');
                }
            })
        }
    }, [zipcode])

    const getView = () => {
        switch (view) {
            case PageView.SOURCE:
                return <UsageSource
                            handleClick={(type: string): void => {
                                if (type === 'smt') setView(PageView.SMTINFO);
                                if (type === 'tdu') setView(PageView.TDUINFO);
                                if (type === 'myaccount') setView(PageView.MYACCOUNTINFO);
                            }}
                            recommend={recommend}
                        />;
            case PageView.SMTINFO:
                return <SmtGetInfo
                            handleShowAgreement={(concentId: string, email: string) => {
                                setConsentId(concentId);
                                setEmail(email);
                                setView(PageView.SMTAGREEMENT);
                                handleSetConsentId(concentId);
                            }}
                            handleBack={() => {
                                if (defaultView) {
                                    navigate(-1);
                                }
                                else {
                                    setView(PageView.SOURCE);
                                }
                            }}
                            hideBack={(inModal && defaultView) ? true : false}
                        />;
            case PageView.SMTAGREEMENT:
                return <SmtShowAgreement
                            consentId={consentId}
                            handleBack={
                                () => setView(PageView.SMTINFO)
                            }
                            email={email}
                            handleSetUsage={(data: any) => {
                                setUsage(data);
                                sourceFrom.current = 'smt';
                                setView(PageView.USAGE);
                            }}
                        />;
            case PageView.MYACCOUNTINFO:
                return <MyAccountInfo
                            handleShowAgreement={(concentId: string, email: string) => {
                                setConsentId(concentId);
                                setEmail(email);
                                setView(PageView.SMTAGREEMENT);
                                handleSetConsentId(concentId);
                            }}
                            handleBack={() => {
                                if (defaultView) {
                                    navigate(-1);
                                }
                                else {
                                    setView(PageView.SOURCE);
                                }
                            }}
                            hideBack={(inModal && defaultView) ? true : false}
                        />;
            case PageView.TDUINFO:
                return <TduGetInfo
                            handleBack={() => {
                                if (defaultView) {
                                    navigate(-1);
                                }
                                else {
                                    setView(PageView.SOURCE);
                                }
                            }}
                            showSmtView={() => {
                                setView(PageView.SMTINFO);
                            }}
                            showMyaccountView={() => {
                                setView(PageView.MYACCOUNTINFO);
                            }}
                            continueWithForecast={() => {
                                forcastUsage();
                            }}
                            handleSetUsage={(data: any) => {
                                setUsage(data);
                                sourceFrom.current = 'tdu';
                                setView(PageView.USAGE);
                            }}
                            hideBack={(inModal && defaultView) ? true : false}
                        />;
            case PageView.USAGE: 
                return <ShowUsage
                            source={sourceFrom.current}
                            data={usage}
                        />
            default: return null;
        }
    }

    return (
        <div>
            { getView() }
        </div>
    )
}

const UsageSource = ({ handleClick, recommend }: {
    handleClick: (type: string) => void,
    recommend: string
}) => {
    return (
        <div>
            <h2>Choose Usage Source</h2>
            {
                !recommend &&
                <div><LoadingRow /></div>
            }
            {
                recommend &&
                <div id="get-usage-option-wrapper">
                    {
                        recommend === 'tdu' &&
                        <div className="get-usage-option recommend" onClick={() => { handleClick('tdu') }}>
                            <img src="/images/icon-actual-usage.png" width="64" alt="" />
                            <div>
                                <h5>Fetch My Actual Usage</h5>
                                <p>Pull your actual usage history from your utility</p>
                            </div>
                            <i>Recommend</i>
                        </div>
                    }
                    <div className={`get-usage-option ${recommend === 'smt' ? 'recommend' : ''}`} onClick={() => { handleClick('myaccount') }}>
                        <img src="/images/icon-myaccount.png" width="64" alt="" />
                        <div>
                            <h5>MyAccount</h5>
                            <p>Instant access of your usage history through MyAccount</p>
                        </div>
                        { recommend === 'smt' && <i>Recommend</i> }
                    </div>
                    <div className="get-usage-option" onClick={() => { handleClick('smt') }}>
                        <img src="/images/icon-smart-meter.png" width="64" alt="" />
                        <div>
                            <h5>Use Smart Meter Texas (SMT) Data</h5>
                            <p>Instant access of your usage history through your smart meter number</p>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}