import { API_URL, REPs, API_KEY, POWERLEGO_API_URL, GOOGLE_API_KEY } from "../config";

export const converArrToSearchParams = (name:string, arr: string[]|number[]) => {
    return arr.map((val, key) => {
        return `${name}[${key}]=${val}`;
    }).join('&');
}

export const getRepIds = () => {
    return REPs.map(val => val.providerId);
}

export const fetchTerms = async (utilityCode: string) => {
    const hostId = REPs.filter(val => val.host)[0].providerId;

    try {
        const res = await fetch(`${API_URL}/get_contract_terms`,
            {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${API_KEY}`
                },
                body: `provider_id=${hostId}&utility_code=${utilityCode}`
            }
        )
        return res.json()
    }
    catch(err) {
        console.error(err);
    }
}

export const fetchPlan = async (
    term: number,
    utilityCode: string,
    usages: number[]
) => {
    try {
        const res = await fetch(`${API_URL}/get_average_price`,
            {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${API_KEY}`
                },
                body: `${converArrToSearchParams('provider_ids', getRepIds())}&term=${term}&utility_code=${utilityCode}&${converArrToSearchParams('usages', usages)}`
            }
        )
        return res.json()
    }
    catch(err) {
        console.error(err);
    }
}

export const fetchUtility = async (zipcode: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${API_KEY}`);
    
    const formdata = new FormData();
    formdata.append('zipcode', zipcode);

    const url = `${POWERLEGO_API_URL}/v1/get_utility`

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    })
    return response.json()
}

export const fetchAddress = async (val: string) => {
    const string = val.toString().replace(/#/, '')

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${API_KEY}`);
    
    const formdata = new FormData();
    formdata.append("term", string);
    
    const url = `${POWERLEGO_API_URL}/v1/address_validator`

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    })
    return response.json()
}

export const fetchEstUsage = async (
    address: string, city: string, state: string, zipcode: string
) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${API_KEY}`);

    const formdata = new FormData();
    formdata.append("address1", address);
    formdata.append("city", city);
    formdata.append("state", state);
    formdata.append("zipcode", zipcode);

    const url = `${POWERLEGO_API_URL}/v1/usage_estimator`

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    })
    return response.json()
}

export const fetchPremiseDetail = async (zipcode: string, address: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${API_KEY}`);

    const formdata = new FormData();
    formdata.append("zipcode", zipcode);
    formdata.append("address", address);

    const url = `${POWERLEGO_API_URL}/v2/premise/detail`

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    })
    return response.json()
}

export const fetchCoordinates = async (address: string) => {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_API_KEY}`);
    return response.json();
}

export const fetchRetailElectricProviders = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${API_KEY}`);

    const url = `${POWERLEGO_API_URL}/v2/smt/retail_electric_providers`

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    })
    return response.json()
}

export const newAgreement = async (
    esiid: string, meterNumber: string, certificateNumber: string, email: string, forwardEmail: string
) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${API_KEY}`);
    
    const formdata = new FormData();
    formdata.append("esiid", esiid);
    formdata.append("meter_number", meterNumber);
    formdata.append("certificate_number", certificateNumber);
    formdata.append("email", email);
    formdata.append("email_forward", forwardEmail);

    const url = `${POWERLEGO_API_URL}/v2/smt/new_agreement`

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    })
    return response.json()
}

export const fetchMonthlyUsage = async (
    esiid: string, consentId: string, startDate: string, endDate: string
) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${API_KEY}`);

    const formdata = new FormData();
    formdata.append("consent_id", consentId);
    formdata.append("esiid", esiid);
    formdata.append("start_date", startDate);
    formdata.append("end_date", endDate);

    const url = `${POWERLEGO_API_URL}/v2/smt/monthly`

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    })
    return response.json()
}

export const getAgreement = async (consentId: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${API_KEY}`);
    
    const formdata = new FormData();
    formdata.append("consent_id", consentId);
    formdata.append("vip", "1");

    const url = `${POWERLEGO_API_URL}/v2/smt/get_agreement`

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    })
    return response.json()
}

export const fetchRepList = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${API_KEY}`);

    const url = `${POWERLEGO_API_URL}/v1/rep_list`

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    })
    return response.json()
}

export const getMyAccountInfo = async (
    username: string, password: string, rep: string
) => {
    const myHeaders = new Headers();
    
    myHeaders.append("Authorization", `Bearer ${API_KEY}`);
    
    const formdata = new FormData();
    formdata.append("username", username);
    formdata.append("password", password);
    formdata.append("rep", rep);
    formdata.append("method", '0');
    
    const url = `${POWERLEGO_API_URL}/v1/rep_usage`

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    })
    return response.json()
}
